// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dziekujemy-tsx": () => import("./../../../src/pages/dziekujemy.tsx" /* webpackChunkName: "component---src-pages-dziekujemy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-oferta-tsx": () => import("./../../../src/pages/oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-regulamin-tsx": () => import("./../../../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */),
  "component---src-templates-axell-logistics-tsx": () => import("./../../../src/templates/axell-logistics.tsx" /* webpackChunkName: "component---src-templates-axell-logistics-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-blue-media-tsx": () => import("./../../../src/templates/blue-media.tsx" /* webpackChunkName: "component---src-templates-blue-media-tsx" */),
  "component---src-templates-droids-on-roids-tsx": () => import("./../../../src/templates/droids-on-roids.tsx" /* webpackChunkName: "component---src-templates-droids-on-roids-tsx" */),
  "component---src-templates-eurobank-tsx": () => import("./../../../src/templates/eurobank.tsx" /* webpackChunkName: "component---src-templates-eurobank-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-template-tsx": () => import("./../../../src/templates/template.tsx" /* webpackChunkName: "component---src-templates-template-tsx" */)
}

